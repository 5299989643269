
import { Component, Vue, Watch } from "vue-property-decorator";
import { RouteMeta } from "vue-router/types/router";

declare type breadcrumb = { name?: string; link?: string };

@Component
export default class Breadcrumb extends Vue {
  //data
  breadcrumbList: breadcrumb[] = [{ name: "", link: "" }];

  //hook
  mounted(): void {
    this.updateBreadcrumbList();
  }
  //methods
  routeTo(index: number): void {
    if (this.breadcrumbList[index].link)
      this.$router.push(this.breadcrumbList[index].link as string);
  }
  updateBreadcrumbList(): void {
    this.breadcrumbList = (this.$route.meta as RouteMeta).breadcrumb as breadcrumb[];
  }

  // watch
  @Watch("$route")
  watchRouter():void {
    this.updateBreadcrumbList();
  }  
}
